export default () => [
	{
		path: '/pre-cybermonday',
		name: 'pre-cybermonday',
		meta: { appComponent: 'PreCyberMonday' },
	},
	{
		path: '/pre-hotsale',
		name: 'pre-hotsale',
		meta: { appComponent: 'PreHotsale' },
	},
]

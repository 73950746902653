<template>
	<v-container class="container base rounded pb-0">
		<slot></slot>
	</v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.container {
	border: 1px solid #eee;
}
</style>

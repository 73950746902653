<script>
import groupBy from 'lodash/groupBy'

export default {
	data() {
		return {
			tags: this.$srv('productCardTags', []),
		}
	},
	props: {
		product: Object,
		selectedVariant: Object,
		verticalCard: { type: Boolean, default: true },
		financing: [String, Array],
		cardType: Object,
		featured: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
	},

	computed: {
		featuredCardWithAttrsOnTop() {
			return this.financing?.length && !this.verticalCard && this.featured
		},

		cardTypeWithAttrsOnTop() {
			return this.cardType.name.includes('4') || this.cardType.name.includes('6')
		},

		matchedAttrs() {
			let attrs = this.tags
				.flatMap((tag) => {
					if (tag.collectionId) {
						let inCollection = this.product.collectionsIds?.some((c) => c == tag.collectionId)

						if (!inCollection) return false
					}

					return this.product.attrs.filter((attr) => attr.attrKeyId == tag.attrKeyId)
				})
				.filter((x) => !!x)

			return attrs
		},

		resultantAttrs() {
			if (this.matchedAttrs?.length) {
				let group = groupBy(this.matchedAttrs, (t) => t.attrKeyId)
				let keys = Object.keys(group)
				let resultantTags

				if (keys?.length) {
					resultantTags = keys.map((k) => {
						for (const g of group[k]) {
							return g
						}
					})
					if (resultantTags?.length < 3) {
						keys.forEach((key) => {
							let attribute = resultantTags.find((r) => r.attrKeyId == key)
							let result = group[key].find((g) => g.v != attribute.v)
							if (result) resultantTags.push(result)
						})
					}
					if (this.featuredCardWithAttrsOnTop && this.cardTypeWithAttrsOnTop)
						return resultantTags.slice(0, 1)
					else return resultantTags.slice(0, 4)
				}
				return resultantTags.slice(0, 4)
			} else return null
		},

		verticalClasses() {
			let design = this.cardType.name.toLowerCase()
			if (this.small && (design.includes('4') || design.includes('6'))) {
				return `product-card-tags-vertical-${design} product-card-tags-vertical-${design}--small`
			}
			return `product-card-tags-vertical-${design}`
		},

		horizontalClasses() {
			let design = this.cardType.name.toLowerCase()
			if (this.featured) return `product-card-tags-with-timer-horizontal-${design}`
			else return `product-card-tags-horizontal-${design}`
		},
	},
}
</script>

<template>
	<!--  VERTICAL CARD TAGS -->
	<v-container
		:class="[$bem('product-card-tags-vertical'), verticalClasses]"
		class="pa-0 pt-1"
		v-if="verticalCard"
	>
		<div
			class="d-flex align-center"
			v-if="financing && financing.length > 0"
			:class="[
				$bem('__financing-value'),
				{
					'mb-2': !resultantAttrs && !small,
					'my-1': !small,
					'justify-center': cardType.alignment == 'center' && !cardTypeWithAttrsOnTop,
					'justify-end': cardType.alignment == 'right' && !cardTypeWithAttrsOnTop,
					'px-2': cardTypeWithAttrsOnTop && !small,
				},
			]"
		>
			<span
				class="font-0 px-2 d-inline-block primary font-weight-medium product-card-tags-vertical__financing-value--rounded"
				:class="[
					!resultantAttrs
						? 'product-card-tags-vertical__financing-value--max-width'
						: 'product-card-tags-vertical__financing-value--character-amount',
					{ 'product-card-tags-vertical__financing-value--max-width-mobile': $b.m },
				]"
			>
				{{ financing }}
			</span>
		</div>
		<div :class="$bem('__attrs-wrapper')">
			<div
				class="d-flex align-center"
				v-if="resultantAttrs"
				:class="{
					'justify-center': cardType.alignment == 'center' && !cardTypeWithAttrsOnTop,
					'justify-end': cardType.alignment == 'right' && !cardTypeWithAttrsOnTop,
					'px-2': cardTypeWithAttrsOnTop && !small,
					'py-1': small,
				}"
			>
				<div v-for="(attr, index) of resultantAttrs" :key="index" class="d-flex flex-column">
					<span
						class="px-2 font-0 mr-1 secondary font-weight-medium d-inline-block line-clamp-1 py-1"
						:class="[
							$bem('__attr-value'),
							{ 'product-card-tags-vertical__attr-value--max-width-mobile': $b.m },
						]"
					>
						{{ attr.v }}
					</span>
				</div>
			</div>
		</div>
		<div :class="[!resultantAttrs ? 'product-card-tags-vertical__div-fixed-height' : 'd-none']"></div>
	</v-container>
	<!-- END OF VERTICAL CARD TAGS -->

	<!-- HORIZONTAL CARD TAGS FOR MOBILE -->
	<v-container :class="[$bem('product-card-tags-horizontal'), horizontalClasses]" class="pa-0 mb-1" v-else>
		<div class="d-flex align-center" v-if="resultantAttrs">
			<div v-for="(attr, index) of resultantAttrs" :key="index" class="d-flex flex-column my-1">
				<span
					class="px-2 font-0 mr-1 secondary font-weight-medium d-inline-block line-clamp-1"
					:class="$bem('__attr-value')"
				>
					{{ attr.v }}
				</span>
			</div>
		</div>
		<div
			class="d-flex align-center"
			v-if="financing && financing.length > 0"
			:class="[$bem('__financing-value'), { 'my-1': !resultantAttrs && !$b.m }]"
		>
			<span
				class="font-0 px-2 d-inline-block primary font-weight-medium product-card-tags-horizontal__financing-value--rounded"
				:class="
					!resultantAttrs
						? 'product-card-tags-horizontal__financing-value--max-width'
						: 'product-card-tags-horizontal__financing-value--character-amount'
				"
				v-html="financing"
			>
			</span>
		</div>
	</v-container>
	<!-- END OF HORIZONTAL CARD TAGS FOR MOBILE -->
</template>

<style scoped lang="scss">
.product-card-tags-vertical {
	height: 60px;

	&__attr-value {
		border-radius: 2px;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 62px;
		max-height: 26px;
		white-space: nowrap;

		&--max-width-mobile {
			max-width: 52px;
		}
	}

	&__financing-value {
		&--rounded {
			border-radius: 2px;
		}

		&--max-width {
			max-width: 196px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&--character-amount {
			max-height: 19px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&--max-width-mobile {
			max-width: 166px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&__div-fixed-height {
		height: 42px;
	}
}

.product-card-tags-vertical-design4,
.product-card-tags-vertical-design6 {
	position: absolute;
	top: 0;

	&--small {
		top: -7px !important;
	}
}

.product-card-tags-horizontal {
	&__attr-value {
		border-radius: 2px;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 62px;
		max-height: 26px;
		white-space: nowrap;
	}

	&__financing-value {
		&--rounded {
			border-radius: 2px;
		}

		&--character-amount {
			max-height: 19px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&--max-width {
			max-width: 196px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

.product-card-tags-with-timer-horizontal-design4,
.product-card-tags-with-timer-horizontal-design6 {
	position: absolute;
	top: 2px;
	left: 6px;
	display: flex;
	column-gap: 10px;
}
</style>

<script>
export default {
	props: ['ProductCard'],
	hookZone: ['attrs&financingTags', 'attrs&financingTagsHorizontal'],
	computed: {
		cardType() {
			return this.ProductCard.configData?.name || 'Design1'
		},
	},
}
</script>

<template>
	<ProductCardTags
		:product="ProductCard.product"
		:selectedVariant="ProductCard.selectedVariant"
		:verticalCard="ProductCard.verticalCard"
		:financing="ProductCard.financingTagHtml"
		:cardType="ProductCard.configData"
		:featured="ProductCard.featured"
		:small="ProductCard.small"
	/>
</template>

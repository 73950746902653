<script>
export default {
	props: ['ProductPage'],
	hookZone: ['price-layout'],
	computed: {
		selectedVariant() {
			return this.ProductPage.selectedVariant
		},
		product() {
			return this.ProductPage.product
		},
	},
}
</script>

<template>
	<div>
		<CustomPriceLayout
			prevPriceBottom
			alternativeDiscountPosition
			:prev-price="selectedVariant.pvPrice.prevPrice"
			:price="selectedVariant.pvPrice.price"
			:discount-pct="selectedVariant.pvPrice.discountPct"
			:unit-metric="product.packMetric ? product.unitMetric : null"
			:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
			discount-class="ml-4"
			price-class="font-8"
		/>
	</div>
</template>

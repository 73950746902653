import { make } from 'vuex-pathify'

const setupStore = (key, options) => {
	let { srv, ssr, store } = options

	let secondsLeft = Math.max(0, Number(srv(key + 'SecondsLeft') || 0))

	const state = { secondsLeft }

	store.registerModule(key, {
		namespaced: true,
		state,
		mutations: make.mutations(state),
		actions: make.actions(state),
	})
	if (!ssr && secondsLeft) {
		let itv = setInterval(() => {
			store.set(key + '/secondsLeft', --secondsLeft)
			if (!secondsLeft) clearInterval(itv)
		}, 1000)
	}
}

export default (options) => {
	const keys = ['cybermonday', 'hotsale']
	keys.forEach((key) => setupStore(key, options))
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.verticalCard)?_c('v-container',{staticClass:"pa-0 pt-1",class:[_vm.$bem('product-card-tags-vertical'), _vm.verticalClasses]},[(_vm.financing && _vm.financing.length > 0)?_c('div',{staticClass:"d-flex align-center",class:[
			_vm.$bem('__financing-value'),
			{
				'mb-2': !_vm.resultantAttrs && !_vm.small,
				'my-1': !_vm.small,
				'justify-center': _vm.cardType.alignment == 'center' && !_vm.cardTypeWithAttrsOnTop,
				'justify-end': _vm.cardType.alignment == 'right' && !_vm.cardTypeWithAttrsOnTop,
				'px-2': _vm.cardTypeWithAttrsOnTop && !_vm.small,
			} ]},[_c('span',{staticClass:"font-0 px-2 d-inline-block primary font-weight-medium product-card-tags-vertical__financing-value--rounded",class:[
				!_vm.resultantAttrs
					? 'product-card-tags-vertical__financing-value--max-width'
					: 'product-card-tags-vertical__financing-value--character-amount',
				{ 'product-card-tags-vertical__financing-value--max-width-mobile': _vm.$b.m } ]},[_vm._v(" "+_vm._s(_vm.financing)+" ")])]):_vm._e(),_c('div',{class:_vm.$bem('__attrs-wrapper')},[(_vm.resultantAttrs)?_c('div',{staticClass:"d-flex align-center",class:{
				'justify-center': _vm.cardType.alignment == 'center' && !_vm.cardTypeWithAttrsOnTop,
				'justify-end': _vm.cardType.alignment == 'right' && !_vm.cardTypeWithAttrsOnTop,
				'px-2': _vm.cardTypeWithAttrsOnTop && !_vm.small,
				'py-1': _vm.small,
			}},_vm._l((_vm.resultantAttrs),function(attr,index){return _c('div',{key:index,staticClass:"d-flex flex-column"},[_c('span',{staticClass:"px-2 font-0 mr-1 secondary font-weight-medium d-inline-block line-clamp-1 py-1",class:[
						_vm.$bem('__attr-value'),
						{ 'product-card-tags-vertical__attr-value--max-width-mobile': _vm.$b.m } ]},[_vm._v(" "+_vm._s(attr.v)+" ")])])}),0):_vm._e()]),_c('div',{class:[!_vm.resultantAttrs ? 'product-card-tags-vertical__div-fixed-height' : 'd-none']})]):_c('v-container',{staticClass:"pa-0 mb-1",class:[_vm.$bem('product-card-tags-horizontal'), _vm.horizontalClasses]},[(_vm.resultantAttrs)?_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.resultantAttrs),function(attr,index){return _c('div',{key:index,staticClass:"d-flex flex-column my-1"},[_c('span',{staticClass:"px-2 font-0 mr-1 secondary font-weight-medium d-inline-block line-clamp-1",class:_vm.$bem('__attr-value')},[_vm._v(" "+_vm._s(attr.v)+" ")])])}),0):_vm._e(),(_vm.financing && _vm.financing.length > 0)?_c('div',{staticClass:"d-flex align-center",class:[_vm.$bem('__financing-value'), { 'my-1': !_vm.resultantAttrs && !_vm.$b.m }]},[_c('span',{staticClass:"font-0 px-2 d-inline-block primary font-weight-medium product-card-tags-horizontal__financing-value--rounded",class:!_vm.resultantAttrs
					? 'product-card-tags-horizontal__financing-value--max-width'
					: 'product-card-tags-horizontal__financing-value--character-amount',domProps:{"innerHTML":_vm._s(_vm.financing)}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
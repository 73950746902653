<script>
export default {
	props: ['ProductCard'],
	hookZone: ['image'],
	computed: {
		cardType() {
			return this.ProductCard.configData?.name || 'Design1'
		},
		cardTypeClass() {
			let design = this.cardType.toLowerCase()
			let orientation = this.ProductCard.verticalCard ? 'vertical' : 'horizontal'

			if (this.ProductCard.featured) return `product-card-${design}-with-timer-${orientation}`
			else if (this.ProductCard.small) return `product-card-${design}-small-${orientation}`
			else return `product-card-${design}-${orientation}`
		},
	},
}
</script>

<template>
	<div :class="$bem(cardTypeClass)">
		<Media :class="$bem('__main-image')" :src="ProductCard.mainImage" width="100%" aspect-ratio="1" />
		<div
			v-if="ProductCard.secondImage && ProductCard.firstImageHover && ProductCard.imgHover"
			:class="$bem('__second-image-cont')"
		>
			<Media
				:src="ProductCard.secondImage"
				width="100%"
				:class="[$bem('__second-image'), ProductCard.hover ? $bem('__second-image--show') : '']"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.product-card-design1-vertical,
.product-card-design2-vertical,
.product-card-design3-vertical,
.product-card-design4-vertical,
.product-card-design5-vertical,
.product-card-design6-vertical,
.product-card-design7-vertical,
.product-card-design8-vertical,
.product-card-design1-small-vertical,
.product-card-design2-small-vertical,
.product-card-design3-small-vertical,
.product-card-design4-small-vertical,
.product-card-design5-small-vertical,
.product-card-design6-small-vertical,
.product-card-design7-small-vertical,
.product-card-design8-small-vertical,
.product-card-design1-with-timer-vertical,
.product-card-design2-with-timer-vertical,
.product-card-design3-with-timer-vertical,
.product-card-design4-with-timer-vertical,
.product-card-design5-with-timer-vertical,
.product-card-design6-with-timer-vertical,
.product-card-design7-with-timer-vertical,
.product-card-design8-with-timer-vertical {
	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}
}

.product-card-design3-vertical,
.product-card-design4-vertical,
.product-card-design6-vertical,
.product-card-design7-vertical {
	&__main-image {
		position: relative;
	}

	::v-deep img.ratio-image__image {
		height: calc(100% - 60px);
		bottom: 10px;
		top: unset;
	}
}

.product-card-design3-small-vertical,
.product-card-design4-small-vertical,
.product-card-design6-small-vertical,
.product-card-design7-small-vertical {
	&__main-image {
		position: relative;
	}

	::v-deep img.ratio-image__image {
		height: calc(100% - 65px);
		bottom: 20px;
		top: unset;
	}
}

.product-card-design4-with-timer-vertical,
.product-card-design6-with-timer-vertical {
	&__second-image-cont {
		transform: translateY(60px);
	}

	&__main-image {
		transform: translateY(60px);
	}
}
</style>

<script>
import BaseImagesCarousel from '#/v-shop/vue-src/v-shop/dynamic-pages-components/comp_dpc-ImagesCarousel.vue'
export default {
	extends: BaseImagesCarousel,
}
</script>

<template>
	<v-container class="no-mobile-px" v-if="data.slides" :class="$bem('images-carousel')">
		<v-row
			class="mx-1 my-3"
			v-if="data.title"
			:class="{
				'justify-center align-center': data.titleStyle == 'double',
				'modern-row': data.titleStyle == 'modern',
				'mx-4': $b.m,
			}"
		>
			<div class="col d-flex double-col pa-0 mr-2" v-if="data.titleStyle == 'double'">
				<div class="double"></div>
				<div class="double"></div>
			</div>
			<h2
				class="header d-block"
				:class="{
					modern: data.titleStyle == 'modern',
					short: data.titleStyle == 'short',
					'font-4': $b.td,
					'font-3': $b.m,
				}"
			>
				{{ data.title }}
			</h2>
			<div class="col d-flex double-col pa-0 ml-2" v-if="data.titleStyle == 'double'">
				<div class="double"></div>
				<div class="double"></div>
			</div>
		</v-row>

		<v-row>
			<v-col>
				<Carousel
					loop
					v-bind="
						$b.props({
							feather: {
								d: data.slides.length > 6 && 30,
								t: data.slides.length > 4 && 100,
								m: data.slides.length > 2 && 20,
							},
							showArrows: {
								d: data.slides.length > 6 && true,
								t: data.slides.length > 4 && true,
							},
							slidesPerPage: { m: 2, t: 4, d: 6 },
							peek: {
								d: data.slides.length > 6 && 60,
								t: data.slides.length > 4 && 30,
								m: data.slides.length > 2 && 40,
							},
							gutter: 0,
							paginateBySlide: true,
						})
					"
				>
					<div v-for="(item, i) of data.slides" :key="i" class="px-2 px-sm-3">
						<SafeLink :to="getToLink(item)">
							<!-- TEXTO ARRIBA -->
							<div
								v-if="item.textEnabled && item.textPosition == 'above'"
								class="w100 text-center my-2"
								:class="$bem('__text-above')"
							>
								<h3 class="font-weight-bold font-2 strong">
									{{ item.textTitle }}
								</h3>
								<h4
									v-if="item.textSubtitle"
									:class="{ 'subtitle-mobile': $b.mt }"
									class="font-weight-medium font-1 base--text"
								>
									{{ item.textSubtitle }}
								</h4>
							</div>
							<Media
								:src="item.img"
								width="100%"
								:imgClass="[
									data.imgBorder == 'border'
										? 'rounded-lg border'
										: data.imgBorder == 'circle'
										? 'img-circle'
										: 'border',
								]"
							>
								<div
									v-if="item.textEnabled"
									class="h100"
									:class="[{ 'd-flex flex-column justify-center': item.textPosition == 'over' }]"
								>
									<!-- TEXTO DENTRO -->
									<div
										v-if="item.textPosition == 'inside'"
										class="w100 h100 background-overlay pa-5 d-flex flex-column justify-center align-center white--text"
										:class="[
											$bem('__text-inside'),
											data.imgBorder == 'circle'
												? 'img-circle'
												: data.imgBorder == 'border' && 'rounded-lg',
										]"
									>
										<div class="text-center">
											<h3 class="font-weight-bold font-1">
												{{ item.textTitle }}
											</h3>
											<h4
												v-if="item.textSubtitle"
												:class="{ 'subtitle-mobile': $b.mt }"
												class="font-weight-medium font-0 mt-1"
											>
												{{ item.textSubtitle }}
											</h4>
										</div>
									</div>

									<!-- TEXTO SEO -->
									<div v-else class="seo-text" :class="$bem('__text-seo')">
										<div>
											<h3>{{ item.textTitle }}</h3>
											<h4 v-if="item.textSubtitle">{{ item.textSubtitle }}</h4>
										</div>
									</div>
								</div>
							</Media>

							<!-- TEXTO ABAJO -->
							<div
								v-if="item.textEnabled && item.textPosition == 'below'"
								class="w100 text-center my-2"
							>
								<h3 class="font-weight-bold font-2 strong">
									{{ item.textTitle }}
								</h3>
								<h4
									v-if="item.textSubtitle"
									:class="{ 'subtitle-mobile': $b.mt }"
									class="font-weight-medium font-1 base--text"
								>
									{{ item.textSubtitle }}
								</h4>
							</div>
						</SafeLink>
					</div>
				</Carousel>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
.background-overlay {
	background-color: #424242aa;
}

::v-deep .border {
	border: 1px solid #eaeaea;
}

::v-deep .img-circle {
	border-radius: 50%;
	border: 1px solid #eaeaea;
}

.modern-row {
	border-bottom: 1px solid var(--headertext);
}

h2.modern::after {
	content: '';
	border-bottom: 2px solid var(--primary);
	position: relative;
	width: 102%;
	display: block;
	bottom: -1px;
}

h2.short {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

h2.short::after {
	content: '';
	border-bottom: 3.4px solid var(--primary);
	position: relative;
	width: 20%;
	display: block;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 6px;
}

.double-col {
	flex-direction: column;
}

.double {
	height: 1.4px;
	background: var(--headertext);
	margin: 1px 0;
}
</style>


<script>
export default {
	props: ['ProductCard'],
	hookZone: ['price'],
	computed: {
		cardType() {
			return this.ProductCard.configData?.name || 'Design1'
		},
		designs() {
			return this.cardType == 'Design6' || this.cardType == 'Design7'
		},
	},
}
</script>

<template>
	<div>
		<PriceText
			:amount="ProductCard.priceAmount"
			:currency="ProductCard.currency"
			:priceClass="ProductCard.priceClass"
		/>
		<div
			v-if="designs && !ProductCard.prevPriceAmount"
			:style="{ height: $b.d || cardType == 'Design7' ? '36px' : '26px' }"
		></div>
	</div>
</template>

<style scoped lang="scss"></style>
